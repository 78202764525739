import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch, BrowserRouter } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import HomeV2 from './components/home-v2';
import CatPage from './components/cat-page';
import CatSports from './components/cat-sports';
import lifeStyle from './components/cat-life-style';
import Fashion from './components/cat-fashion';
import Author from './components/author';
import Error from './components/error';
import Blog from './components/blog';
import News from './components/news';
import BlogDetails from './components/blog-details';
import NewsDetails from './components/news-details';
import { Provider } from 'react-redux';
import { store } from './store/store';
import CatNews from './components/cat-news';
import Privacy from './components/privacy-policy';
import ContactUs from './components/contact';
import AboutUs from './components/about';
import Events from './components/event-list';
import EventDetailsPage from './components/event-details';



class Root extends Component {
    render() {
        return(
			<Provider store={store}>
                <BrowserRouter>
				<ScrollToTop />
	                <div>
	                <Switch>
	                    <Route exact path="/" component={HomeV2} />
	                    <Route  path="/cat-blog/:catId" component={CatPage} />
						<Route  path="/cat-news/:catId" component={CatNews} />
	                    <Route  path="/cat-sports" component={CatSports} />
	                    <Route  path="/cat-life-style" component={lifeStyle} />
	                    <Route  path="/cat-fashion" component={Fashion} />
	                    <Route  path="/author" component={Author} />
	                    <Route  path="/error" component={Error} />
	                    <Route  path="/blog" component={Blog} />
						<Route  path="/news" component={News} />
	                    <Route  path="/blog-details/:blogId" component={BlogDetails} />
						<Route  path="/news-details/:newsId" component={NewsDetails} />
						<Route  path="/privacy-policy" component={Privacy} />
						<Route  path="/contact" component={ContactUs} />
						<Route path="/about" component={AboutUs} />
						<Route  path="/event" component={Events} />
						<Route  path="/event-detail/:eventId" component={EventDetailsPage} />
	                </Switch>
	                </div>
                </BrowserRouter>
			</Provider>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('nextpage'));
