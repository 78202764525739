import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const RelatedPost = ({ publicUrl, categoryId, imageFetchPath, newsId }) => {
    const [relatedPosts, setRelatedPosts] = useState([]);

    useEffect(() => {
        if (categoryId && newsId) {
            // Fetch related posts based on categoryId and newsId
            fetch(`https://muslimvoicenetwork.com/api/blog.php?news=related_posts&cat_id=${categoryId}&news_id=${newsId}&limit=6`)
                .then(response => response.json())
                .then(data => {
                    setRelatedPosts(data); // Assuming 'data' is the array of related posts
                })
                .catch(error => {
                    console.error('Error fetching related posts:', error);
                });
        }
    }, [categoryId, newsId]); // Make sure newsId and categoryId are dependencies

    return (
        <div className="related-post">
            <div className="section-title mb-0">
                <h5 className="mb-0">Related Post</h5>
            </div>
            <div className="row justify-content-center">
                {relatedPosts.length > 0 ? relatedPosts.map(post => (
                    <div className="col-lg-4 col-md-6" key={post.id}>
                        <div className="single-post-wrap">
                            <div className="thumb">
                                <img src={post.post_image ? `${post.post_image}` : `${publicUrl}assets/img/post/19.png`} alt={post.title} />
                                <Link className="tag-base tag-red" to={`/cat-news/${post.category_id}`}>{post.category}</Link>
                            </div>
                            <div className="details">
                                <h6>
                                    <Link to={{
                                        pathname: `/news-details/${post.id}`, // Existing route with news ID
                                        state: { news: post } // Passing the news data as state
                                    }}>
                                        {post.title}
                                    </Link>
                                </h6>
                                <div className="blog-meta">
                                    <span className="date"><i className="fa fa-clock-o"></i> {new Date(post.updated_at || post.created_at).toLocaleDateString()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : (
                    <div className="col-lg-12">
                        <p>No related posts found.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RelatedPost;
