import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';

const AboutUs = () => {
    return (
        <div>
            <Navbar />
            <PageHeader headertitle="About Us" />
            <div className="container py-5">
                <div className="row g-5">
                    {/* About Section */}
                    <div className="col-lg-6 d-flex align-items-center">
                        <img 
                            src="/assets/img/about/about-card.webp" 
                            alt="Muslim's Voice Network News" 
                            className="img-fluid rounded shadow" 
                        />
                    </div>
                    <div className="col-lg-6">
                        <div className="shadow p-4 bg-white rounded">
                            <h2 className="mb-4">📢 About Muslim's Voice Network News</h2>
                            <p>
                                Welcome to <strong>Muslim's Voice Network News</strong>, a platform committed to amplifying the voices of the Muslim community and shedding light on issues that matter most. Our primary focus is on stories often overlooked by mainstream media, including the struggles, triumphs, and resilience of the people of Gaza, Palestine, and beyond.
                            </p>
                            <p>
                                We are dedicated to bringing you the latest updates, opinions, and analyses across the following categories:
                            </p>
                            <ul className="list-unstyled">
                                <li>📍 <strong>Local:</strong> News from within your community, tailored to your interests.</li>
                                <li>🏛️ <strong>National:</strong> Covering pressing issues and events across the country.</li>
                                <li>🌍 <strong>Global:</strong> A focus on international stories, with special attention to Muslim-majority regions.</li>
                                <li>💬 <strong>Opinion:</strong> Thought-provoking perspectives from diverse voices in the Muslim world.</li>
                                <li>🔍 <strong>Analysis:</strong> In-depth explorations of the events shaping our communities and the world.</li>
                            </ul>
                            <p>
                                At the heart of our mission is a commitment to truth, integrity, and representation. We believe every story matters, and every voice deserves to be heard.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Vision and Mission Section */}
                <div className="row g-5 mt-5">
                    <div className="col-lg-6">
                        <div className="shadow p-4 bg-white rounded">
                            <h2 className="mb-4">🌟 Our Vision</h2>
                            <p>
                                Our vision is to become the leading platform for amplifying Muslim voices worldwide. We strive to foster a sense of unity, awareness, and empowerment by connecting individuals and communities through news that resonates with their values, experiences, and aspirations.
                            </p>
                            <p>
                                We are particularly committed to spotlighting the struggles and stories of regions like Gaza and Palestine, highlighting their resilience and the challenges they face. By focusing on these narratives, we aim to challenge stereotypes and build bridges of understanding across cultures and nations.
                            </p>
                            <p>
                                Through our work, we envision a world where justice, equality, and peace are not just ideals but realities. We believe that by sharing authentic stories and diverse perspectives, we can inspire meaningful change and contribute to a more inclusive global dialogue.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="shadow p-4 bg-white rounded">
                            <h2 className="mb-4">🤲 Our Mission</h2>
                            <p>
                                Our mission is to provide reliable, timely, and meaningful news coverage that reflects the realities of Muslim communities around the world. We aim to offer a platform that not only informs but also educates and inspires action.
                            </p>
                            <p>
                                By focusing on key issues like the humanitarian crisis in Gaza and the ongoing struggles in Palestine, we seek to bring these critical stories to the forefront of global consciousness. Our goal is to ensure that these voices are heard, their struggles acknowledged, and their triumphs celebrated.
                            </p>
                            <p>
                                Through thoughtful reporting, opinion pieces, and in-depth analysis, we are dedicated to highlighting the cultural, social, and political dimensions of the Muslim experience. We aspire to be a trusted source of information and a catalyst for positive change in the Muslim world and beyond.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AboutUs;
