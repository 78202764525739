import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MiniSidebar from './mini-sidebar';

class CategorySectionV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogData: [],
      loading: true,
    };
  }

  componentDidMount() {
    // Fetch data from the API
    fetch('https://muslimvoicenetwork.com/api/blog.php?news=All&limit=12')
      .then((response) => response.json())
      .then((data) => {
        // Assuming the response is an array of blog posts
        this.setState({
          blogData: data, // Store data in state
          loading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching blog data:', error);
        this.setState({ loading: false });
      });
  }

  render() {
    const { blogData, loading } = this.state;
    let publicUrl = process.env.PUBLIC_URL + '/';
	let imageFetchPath = 'https://muslimvoicenetwork.com/public/img/';
	const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
      };

    if (loading) {
      return <div>Loading...</div>; // Display a loading message while fetching data
    }

    // Divide blog posts into columns, 4 posts per column
    const columns = [[], [], []];
    blogData.forEach((post, index) => {
      columns[index % 3].push(post);
    });

    return (
      <div className="post-area bg-sky pd-top-80 pd-bottom-60 go-top">
        <div className="container">
          <div className="row">
            {columns.map((column, columnIndex) => (
              <div key={columnIndex} className="col-xl-3 col-md-6">
                {column.map((post, index) => (
                  <div key={index}>
                    {/* Main post */}
                    {index === 0 ? (
                      <div className="single-post-wrap style-overlay">
                        <div className="thumb">
                          <img src={post.post_image ? `${post.post_image}` : `${publicUrl}assets/img/post/24.png`} alt={post.title || 'image'} />
                          <Link className="tag-base tag-blue" to={`/cat-news/${post.category_id}`}>
                            {post.category}
                          </Link>
                        </div>
                        <div className="details">
                          <div className="post-meta-single">
                            <p>
                              <i className="fa fa-clock-o" />
                              {formatDate(post.updated_at || post.created_at)}
                            </p>
                          </div>
                          <h6 className="title">
                            <Link to={`/news-details/${post.id}`}>{post.title}</Link>
                          </h6>
                        </div>
                      </div>
                    ) : (
                      <div className="single-post-list-wrap">
                        <div className="media">
                          <div className="media-left">
                            <img src={post.post_image ? `${post.post_image}` : `${publicUrl}assets/img/post/list/1.png`} alt={post.title || 'image'} width={100} />
                          </div>
                          <div className="media-body">
                            <div className="details">
                              <div className="post-meta-single">
                                <ul>
                                  <li>
                                    <i className="fa fa-clock-o" />
                                    {formatDate(post.updated_at || post.created_at)}
                                  </li>
                                </ul>
                              </div>
                              <h6 className="title">
                                <Link to={`/news-details/${post.id}`}>{post.title}</Link>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
            <MiniSidebar />
          </div>
        </div>
      </div>
    );
  }
}

export default CategorySectionV2;
